/* Full screen loading */
.overlayWrapper {
  height: 100vh;
}

.viewBox {
  margin: 20px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #284e39;
  color: #ece7e7;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  text-align: center;
}

