/* Full screen loading */
.overlayWrapper {
  height: 100vh;
}

/* Search input style */
.mainInputContainer {
  margin: 5px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #225c3f;
  color:#fff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  max-width: 900px;
}

.mainInputContainer li {
  text-align: right;
  list-style: none;
  margin: 5px;
}

/* Search input style */
.resultContainer {
  margin: 5px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #225c3f;
  color:#fff;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  max-width: 900px;
}

.resultContainer li {
  text-align: center;
  list-style: none;
  margin: 5px;
}

.resultContainer h3 {
  color:#fff;
}

.buttonStyle {
  display: inline-block;
  line-height: 2.2em;
  padding: 0 0.62em;
  border: 1px solid #666;
  border-radius: 0.25em;
  background-image: linear-gradient( to bottom, #fff, #ccc );
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  font-family: arial, sans-serif;
  font-size: 0.8em;
}
.buttonStyle:hover {
  background-image: linear-gradient( to bottom, rgb(226, 223, 223), #ccc );
}
.buttonStyle:active {
  background-image: linear-gradient( to bottom, #fff, #ccc );
}
