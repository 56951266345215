@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: "'Palatino Linotype', 'Book Antiqua', Palatino, serif";
}

body {
  margin: 0;
  background-color: #8A1538;
  height: 100vh;
  color: #ffc107;
  font-family: "'Palatino Linotype', 'Book Antiqua', Palatino, serif";
}

main {
  margin-top: 7rem;
  text-align: center;
  height: 100vh;
}

h1,
h2,
h3,
p {
  color: #ffc107;
}

.form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
}
.form-check-input:checked {
  background-color: #198754;
  border-color: #6668 !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
}

.form-check-input:focus {
  border-color: #6668 !important;
  outline: 0;
  box-shadow: none !important;
}
