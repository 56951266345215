/* Full screen loading */
.overlayWrapper {
  height: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
}

/* Search input style */
.mainInputContainer {
  margin: auto;
  border: 1px solid #064422;
  display: inline-flexbox;
  border-radius: 20px;
  padding: 30px;
  background-color: #284e39;
  color: #ece7e7;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  max-width: 800px;
}

.mainInputContainer li {
  text-align: right;
  list-style: none;
  margin: 5px;
}

/* Search input style */
.resultContainer {
  margin: 5px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #284e39;
  color: #ece7e7;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  max-width: 900px;
}

.resultContainer li {
  text-align: center;
  list-style: none;
  margin: 5px;
}

