/* Full screen loading */
.overlayWrapper {
  position: relative;
  min-height: 130vh;
  margin-bottom: 100px;
}
.viewBox {
  margin: 20px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 10px;
  background-color: #225c3f;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
}

.viewBox li {
  background-color: #198754;
  color: #fff;
}

.tablestyle {
  list-style:none;
  padding: 1px 1px;
  display: flexbox;
  text-align: left;
}
.tablestyle li {
  list-style: none;
  padding: 5px 15px;
  border: 1px solid #064422;
  margin-top: 10px;
  box-shadow: 2px 2px #888888;
  border-radius: 5px;
  display: inline-block;
}
